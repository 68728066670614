<template>
  <div class="cust-box">
    <div class="cust-content">
      <div class="cust-title">
        基本信息
      </div>
      <view-match :list="basicList" />
    </div>
  </div>
</template>

<script>
import viewMatch from '_c/view-match';
import { toArrays } from '@/lib/until.js';

export default {
  components: {
    viewMatch
  },
  data() {
    return {
      basicData: {
        recentRegisterTime: {
          title: '签到时间',
          value: ''
        },
        custCn: {
          title: '拜访客户',
          value: ''
        },
        contactsName: {
          title: '联系人',
          value: ''
        },
        contactsMobile: {
          title: '手机号',
          value: ''
        },
        contactsPosition: {
          title: '职位',
          value: ''
        },
        address: {
          title: '客户地址',
          value: ''
        },
        checkInfo: {
          title: '签到说明',
          value: ''
        },
        checkFiles: {
          type: 'image',
          title: '签到图片',
          image: []
        },
        checkAddr: {
          title: '打卡地址',
          value: ''
        },
        exceptionDes: {
          title: '异常说明',
          value: ''
        }
      }
    };
  },
  computed: {
    basicList() {
      return toArrays(this.basicData);
    }
  },
  methods: {
    getData(data) {
      Object.keys(data).forEach(key => {
        if (this.basicData[key]) {
          if (key === 'checkFiles') {
            this.basicData[key].image = data[key].map(item=>item.fileUrl);
          } else {
            this.basicData[key].value = data[key];
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cust-box {
  width: 100%;
  background-color: $color-FFF;
  text-align: left;
  overflow: hidden;
  .cust-content {
    margin: 22px 14px 0 14px;
    .cust-title {
      font-size: $font-14;
      color: $color-333;
      font-weight: 600;
      margin-bottom: 24px;
    }
  }
  .cust-content:nth-child(2) {
    border-bottom: 0;
    margin-bottom: 0;
  }
}
</style>
