<template>
  <div class="section">
    <cust-header
      class="header"
      :data="signInfoDetail"
    />
    <tab-composite
      ref="tabComposite"
      class="main"
      fixed-height="168"
      :need-btn="needBtn"
      :tab-list="tabList"
      :load-enable="loadEnable"
      :refresh-enable="loadEnable"
      :need-border="false"
      @getIndex="getIndex"
    >
      <ul
        slot="1"
        class="list-wrapper"
      >
        <sign-info ref="signInfo" />
      </ul>
      <ul
        slot="2"
        class="list-wrapper"
      >
        <cust-info ref="custInfo" />
      </ul>
      <ul
        slot="3"
        class="list-wrapper"
      >
        <card-address
          v-for="(item, indexs) of historyList"
          :key="indexs"
          :data="item.mainObj"
          class="out-box"
        />
        <error-page v-if="!historyList.length" />
      </ul>
    </tab-composite>
    <div class="bottom-postion">
      <cube-button
        v-if="index === 0 && btnAid.includes('addfollow')"
        class="btn"
        @click="toFollow"
      >
        添加跟进
      </cube-button>
    </div>
  </div>
</template>

<script>
import custHeader from '_c/cust-header';
import tabComposite from '_c/tab-composite';
import cardAddress from '_c/tab-card/card-address';
import custInfo from '@/views/cust/components/cust-info';
import signInfo from '@/views/sign/components/sign-info';
import errorPage from '_c/errorPage';
import { getCustRegisterDetail } from '../../api/sign';
import dayjs from 'dayjs';
import {findValueByKey} from '../../lib/until';
import btnAuth from '@/mixins/btnAuth';
import { mapGetters } from 'vuex';

export default {
  name: 'SignDetails',
  components: {
    custHeader,
    tabComposite,
    custInfo,
    signInfo,
    cardAddress,
    errorPage
  },
  mixins: [btnAuth],
  data() {
    return {
      id: null,
      loadEnable: false,
      signInfoDetail: {},
      custRegisterDetail: {}, // 签到信息
      custInfoDetail: {}, // 客户信息
      historyList: [], // 历史签到
      tableData: [],
      tabList: [
        {
          label: '签到信息',
          value: 0
        },
        {
          label: '客户信息',
          value: 1
        },
        {
          label: '历史签到',
          value: 2
        }
      ],
      index: 0
    };
  },
  computed: {
    isPersonSign() {
      return this.$route.params.type === '0';
    },
    needBtn() {
      if (this.index === 0 && this.btnAid.includes('addfollow')) {
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters('sign', ['aidList']),
    ...mapGetters(['dictList'])
  },
  created() {
    this.id = this.$route.params.id;
    this.getAuthBtn(this.$route.params.type);
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      getCustRegisterDetail(this.id, this.$route.params.aid).then(res => {
        if (res.flag) {
          this.custRegisterDetail = res.data.custRegisterDetail;
          this.custInfoDetail = res.data.custInfoDetail;
          this.historyList = res.data.historyList;
          this.custRegisterDetail.address = this.custInfoDetail.province + this.custInfoDetail.city + this.custInfoDetail.area + this.custInfoDetail.addrStreet;

          this.$refs.signInfo.getData(this.custRegisterDetail);
          this.$refs.custInfo.getData(this.custInfoDetail);

          this.historyList.forEach(item => {
            let t = dayjs(item.registerDate);
            let fileUrl = item.fileDetails.map(item=>item.fileUrl);
            item.mainObj = {
              checkYear: t.year(),
              checkMonth: (t.month() + 1) + '.' + t.date(),
              checkTime: t.hour() + ':' + t.minute() + ':' + t.second(),
              name: item.registerName,
              fileDetails: fileUrl,
              checkAddr: item.checkAddr,
              branchOrgTitle: item.branchOrgTitle,
              orgTitle: item.registerOrgTitle
              // orgTitle: item.branchOrgTitle ? (item.branchOrgTitle + '-' + item.registerOrgTitle) : item.registerOrgTitle
            };
            if (this.isPersonSign) {
              delete item.mainObj.name;
              delete item.mainObj.orgTitle;
              delete item.mainObj.branchOrgTitle;
            }
          });
          this.signInfoDetail = {
            title: this.custRegisterDetail.custCn,
            status: findValueByKey(this.dictList.registerStatus, this.custRegisterDetail.registerStatus),
            mainList: [
              this.isPersonSign ? null : ('最近签到人：' + this.historyList[0].registerName || ''),
              `最近签到时间：${this.historyList[0].registerDate || ''}`
            ].filter(Boolean)
          };
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getIndex(index) {
      this.index = index;
    },
    toFollow() {
      this.$router.push(
        {
          name: 'AddeditFollow',
          query: {
            custId: this.custRegisterDetail.custId,
            custCn: this.custRegisterDetail.custCn,
            trackTime: this.custRegisterDetail.recentRegisterTime,
            signId: this.$route.params.id,
            from: 'fromSign'
          }
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/btn-bottom.scss";
.header{
  width:100%;
  height:168px;
}
.list-wrapper{
  .out-box:first-child{
    padding-top:24px ;
  }
  .out-box:last-child{
    padding-bottom:24px;
  }
}
</style>
